<div class="rounded-lg fix-action-row modal-body">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold" i18n>Editing Classroom Start Date</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>
  <div mat-dialog-content *ngIf="studentEnrollmentInfo">
    <form class="grid grid-cols-2 gap-2 gap-x-10 p-3" [formGroup]="editForm">
      <div class="col-span-1 mt-2">
        <label class="font-bold mb-1">Rating Period</label>
        <p>
          {{ studentEnrollmentInfo.ratingPeriodName }}
        </p>
      </div>
      <div class="col-span-1 mt-2">
        <label class="font-bold mb-1">Classroom</label>
        <p>
          {{ studentEnrollmentInfo.name }}
        </p>
      </div>
      <div class="col-span-1 w-56">
        <drdp-date-picker
          label="Classroom Start Date"
          i18n-label
          labelClasses="pb-1 whitespace-nowrap"
          [initialDate]="classEnrollmentDate?.value"
          [isRequired]="true"
          [minDate]="studentEnrollmentInfo.enrollmentStartDate"
          [maxDate]="studentEnrollmentInfo.ratingPeriodMaxDate"
          (emitDate)="handleDate($event)">
        </drdp-date-picker>
      </div>
      <div class="col-span-1 mt-2">
        <label class="font-bold mb-1">Classroom Withdrawal Date</label>
        <p>
          {{ studentEnrollmentInfo.classExitDate | date:'MM/dd/yyyy' }}
        </p>
      </div>
      <div class="col-span-1 mt-2">
        <label class="font-bold mb-1">Site</label>
        <p>
          {{ studentEnrollmentInfo.siteName }}
        </p>
      </div>
      <div *ngIf="studentEnrollmentInfo.isDualEnrollment" class="col-span-1 mt-2">
        <drdp-select-age-grade
              #ageGroupInstrumentSelect
              [initialageGradeId]="ageGradeId.value"
              (ageGrade)="handleAgeGrade($event)"
              [stateId]="stateId"
              [multiSelect]="false"
              [required]="true"
              label="Child Age/Grade Instrument"
            >
            </drdp-select-age-grade>
      </div>
    </form>
  </div>
  <div mat-dialog-actions class="flex justify-end">
    <drdp-btn i18n class="mx-2" color="cancel" mat-button mat-dialog-close>Cancel</drdp-btn>
    <drdp-btn
      i18n
      mat-button
      [disabled]="editForm.invalid || editForm.pristine"
      (click)="onSubmit()">Save</drdp-btn>
  </div>
</div>
