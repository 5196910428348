import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControlOptions,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from '@core/services/toast.service';
import { IAgency } from '@core/interfaces/iagency';
import { ISites } from '@core/interfaces/isites';
import { AgencyService } from '@core/services/agency.service';
import { LookupService } from '@core/services/lookup.service';
import { AddEditSitesComponent } from '@views/user/configuration/sites/add-edit-sites/add-edit-sites.component';
import { IRoles } from '@core/interfaces/iroles';
import { SelectType } from '@core/enums/select';
import { Observable, take, tap } from 'rxjs';
import { IAdminUserDetails, IUserRequest, RoleGroupFlag } from '@core/interfaces/iuser';
import {
  AccountStatusNameTable,
  AccountStatusTable,
} from '@core/enums/statusTypes';
import { UserService } from '@core/services/user.service';
import { ICounty } from '@core/interfaces/icounty';
import { SelectComponent } from '@shared/components/dropdowns/select/select.component';
import { transformStringToNumArray } from '@core/services/helper.service';
import { RoleService } from '@core/services/role.service';
import { Role, RoleGroup, RoleGroupAccessLevel } from '@core/enums/roles';
import { AuthService } from '@core/services/auth.service';
import { PermissionService } from '@core/services/permission.service';
import { Permission } from '@core/enums/permissions';

@Component({
  selector: 'drdp-add-edit-user-accounts',
  templateUrl: './add-edit-user-accounts.component.html',
  styleUrls: ['./add-edit-user-accounts.component.scss'],
})
export class AddEditUserAccountsComponent implements OnInit {
  @ViewChild('countySelect') countySelect?: SelectComponent | undefined;
  userForm: FormGroup | any;
  userId?: number | any;
  userDetails?: IAdminUserDetails;
  activeUser?: boolean;
  clearDropdown = false;
  userRoleOptions?: IRoles[];
  hasAccessToAll = this.permissionService.checkPermission(
    Permission.AccessAllAgencies
  );
  hasAccessToAssigned = this.permissionService.checkPermission(
    Permission.AccessAssignedStateAgencies
  );
  canEditSameLevel = this.permissionService.checkPermission(
    Permission.EditSameLevelAccounts
    );
  canEditSameLevelNotPromote = this.permissionService.checkPermission(
    Permission.EditSameLevelNotPromoteSameLevel
    );
  isAdmin = false;
  isLeadAdmin = false;
  isSuperUser = false;
  loggedInUserAccessLevel: number = 1;
  currentUserAccessLevel: number = 1;
  hasStatusEditAccess: boolean = false;
  editSelf: boolean = false;
  loggedUserId: number = this.authService.getCurrentUser().userId;

  selectedSuffix?: string | null;
  selectedStatus?: number | null;
  selectedState?: number | null;
  selectedAgency?: number | null;
  selectedGranteeAgencyId?: number | null;
  selectedContractorAgencyId?: number | null;
  selectedSiteIds: number[] | null = [];
  selectedRoleIds: number[] | null = [];
  selectedCountyIds: number[] | null = [];
  selectedRegionIds: number[] | null = [];
  originalAgency?: number | null;
  orginalSites: number[] | null = [];
  selectedRoleFlags: RoleGroupFlag[] = [];


  isDropdownValid = {
    roles: true,
    state: true,
    agency: true,
    site: true,
    county: true,
  };

  get selectType() {
    return SelectType;
  }
  get statusType() {
    return AccountStatusTable;
  }
  get statusTypeName() {
    return AccountStatusNameTable;
  }
  get roleGroup() {
    return RoleGroup;
  }
  get roleGroupAccessLevel() {
    return RoleGroupAccessLevel;
  }
  get roleType() {
    return Role
  }

  get workEmail() {
    return this.userForm.get('workEmail');
  }
  get checkEmail() {
    return this.userForm.get('checkEmail');
  }
  get jobTitle() {
    return this.userForm.get('jobTitle');
  }
  get firstName() {
    return this.userForm.get('firstName');
  }
  get lastName() {
    return this.userForm.get('lastName');
  }
  get middleName() {
    return this.userForm.get('middleName');
  }
  get suffix() {
    return this.userForm.get('suffix');
  }
  get statusTypeId() {
    return this.userForm.get('statusTypeId');
  }
  get statusReason() {
    return this.userForm.get('statusReason');
  }
  get granteeAgencyId() {
    return this.userForm.get('granteeAgencyId');
  }
  get contractorAgencyId() {
    return this.userForm.get('contractorAgencyId');
  }
  get agencyId() {
    return this.userForm.get('agencyId');
  }
  get roles() {
    return this.userForm.get('roles');
  }
  get sites() {
    return this.userForm.get('sites');
  }
  get counties() {
    return this.userForm.get('counties');
  }
  get state() {
    return this.userForm.get('state');
  }
  get region() {
    return this.userForm.get('region');
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddEditSitesComponent>,
    private agency: AgencyService,
    public toast: ToastService,
    public lookup: LookupService,
    private userService: UserService,
    private roleService: RoleService,
    private authService: AuthService,
    private permissionService: PermissionService,
    @Inject(MAT_DIALOG_DATA) public editData: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.initializeForm();

    this.roleService
                .getAllByOrder()
                .pipe(take(1))
                .subscribe((res: IRoles[]) => {
                  this.userRoleOptions = res;
                });

    this.loggedInUserAccessLevel = this.authService.getAccessLevel();

    if (this.editData) {
      this.userId = this.editData.data.id;
      this.editSelf = this.userId === this.loggedUserId;
      this.userService
        .getAdminUserDetails(this.userId)
        .pipe(
          take(1),
          tap((user: IAdminUserDetails) => {
            this.userDetails = user;
            this.activeUser =
              this.userDetails?.statusTypeId == AccountStatusTable.Active
                ? true
                : false;
            if (this.userDetails) {
              this.initializeDropdownValues(this.userDetails);
              this.selectedRoleFlags = this.userDetails.roleGroupFlags;

              this.userService
                .getUserHighestAccessLevel(this.userId)
                .pipe(take(1))
                .subscribe((res: number) => {
                  if (res) {
                    this.currentUserAccessLevel = res;
                    this.hasStatusEditAccess =
                      this.loggedInUserAccessLevel >=
                      this.currentUserAccessLevel;
                  }
                });
            }
          })
        )
        .subscribe();
    }
  }

  initializeForm(): void {
    this.userForm = this.fb.group(
      {
        workEmail: [
          null,
          [Validators.required, Validators.maxLength(100), Validators.email],
        ],
        checkEmail: [null],
        jobTitle: [null, Validators.maxLength(50)],
        firstName: [null, [Validators.required, Validators.maxLength(50)]],
        middleName: [null, Validators.maxLength(1)],
        lastName: [null, [Validators.required, Validators.maxLength(50)]],
        suffix: [null],
        statusTypeId: [AccountStatusTable.Active, [Validators.required]],
        statusReason: [''],
        agencyId: [null],
        granteeAgencyId: [null],
        contractorAgencyId: [null],
        roles: [null, [Validators.required]],
        sites: [null],
        counties: [null],
        state: [null, [Validators.required]],
        region: [null],
      },
      {
        validator: this.matchEmail('workEmail', 'checkEmail'),
      } as AbstractControlOptions
    );

    this.selectedState = this.editData?.data?.stateId ?? this.authService.getUserStateId();
    this.selectedAgency = this.editData?.data?.agencyId ?? this.authService.getCurrentUser().agencyId;
    this.state.setValue(this.selectedState);
    this.agencyId.setValue(this.selectedAgency);
  }

  matchEmail(emailName: string, confirmEmail: string) {
    return (resetPwForm: FormGroup) => {
      const email = resetPwForm.controls[emailName];
      const confirmedEmail = resetPwForm.controls[confirmEmail];
      if (email.value !== confirmedEmail.value && !this.editData) {
        return confirmedEmail.setErrors({ emailMismatch: true });
      } else {
        return confirmedEmail.setErrors(null);
      }
    };
  }

  initializeDropdownValues(userDetails: IAdminUserDetails) {
    this.selectedAgency = this.editData?.data?.agencyId ?? userDetails.agencyId;
    this.selectedState = this.editData?.data?.stateId ?? userDetails.stateId;
    this.selectedGranteeAgencyId = userDetails.granteeAgencyId;
    this.selectedContractorAgencyId = userDetails.contractorAgencyId;
    this.selectedSiteIds = transformStringToNumArray(userDetails.sites);
    this.selectedRoleIds = transformStringToNumArray(userDetails.roles);
    this.selectedCountyIds = transformStringToNumArray(userDetails.counties);
    this.selectedRegionIds = transformStringToNumArray(userDetails.regions);
    this.selectedStatus = userDetails.statusTypeId;
    this.selectedSuffix = userDetails.suffix;
    this.originalAgency = this.selectedAgency;
    this.orginalSites = this.selectedSiteIds;

    this.userForm.patchValue({
      ...userDetails,
      workEmail: userDetails.emailName,
      jobTitle: userDetails.jobTitle,
      state: userDetails.stateId,
      counties: this.selectedCountyIds,
      roles: this.selectedRoleIds,
      sites: this.selectedSiteIds,
      region: this.selectedRegionIds,
      statusTypeId: this.selectedStatus,
    });
  }

  handleDropdown(data: any, type: string): void {
    switch (type) {
      case this.selectType.States:
        this.selectedState = data ? data.id : null;
        this.state.setValue(this.selectedState);

        if (this.selectedState) {
          this.selectedAgency = null;
          this.agencyId.setValue(this.selectedAgency);
          this.selectedCountyIds = null;
          this.counties.setValue(this.selectedCountyIds);
          this.selectedSiteIds = null;
          this.sites.setValue(this.selectedSiteIds);
        }
        this.isDropdownValid.state = this.selectedState
          ? this.selectedState > 0
          : false;
        break;
      case this.selectType.Agencies:
        this.selectedAgency = data?.id;
        this.agencyId.setValue(this.selectedAgency);

        if (this.selectedAgency === this.originalAgency) {
          this.sites.setValue(this.orginalSites?.map((site: number) => site));
        }
        if (this.agencyId.value) this.sites.setValidators(Validators.required);
        else this.sites.clearValidators();
        this.sites.updateValueAndValidity();
        break;
      case this.selectType.Sites:
        this.sites.setValue(data?.map((site: ISites) => site?.id));
        break;
      case this.selectType.County:
        this.selectedCountyIds = data
          ? data.map((county: ICounty) => county.id)
          : null;
        this.counties.setValue(this.selectedCountyIds);
        break;
      case this.selectType.Grantee:
        this.selectedGranteeAgencyId = data ? data.id : null;
        this.granteeAgencyId.setValue(this.selectedGranteeAgencyId);
        break;
      case this.selectType.StatePreschoolContractorRef:
        this.selectedContractorAgencyId = data ? data.id : null;
        this.contractorAgencyId.setValue(this.selectedContractorAgencyId);
        break;
      case this.selectType.Status:
        this.selectedStatus = data ? data.id : null;
        this.statusTypeId.setValue(this.selectedStatus);
        if (this.statusTypeId.value === this.statusType.Active) {
          this.activeUser = true;
          this.statusReason.reset();
        } else {
          this.activeUser = false;
        }
        break;
      case this.selectType.Suffix:
        this.selectedSuffix = data ? data.name : null;
        this.suffix.setValue(this.selectedSuffix);
        break;
      case this.selectType.RegionRef:
        this.selectedRegionIds = data ? data : null;
        this.region.setValue(this.selectedRegionIds);
        break;
    }
  }

  handleCheckbox(role: IRoles) {
    let roleIncluded = this.selectedRoleIds?.some(
      (roleId: number) => roleId === role.id
    );

    if (roleIncluded) {
      let roleIndex =
        this.selectedRoleIds?.findIndex(
          (roleId: number) => roleId === role.id
        ) ?? 0;
      this.selectedRoleIds?.splice(roleIndex, 1);
      this.selectedRoleFlags = this.selectedRoleFlags.filter(
        (entry) => entry.roleId !== role.id
      );
    } else {
      this.selectedRoleIds?.push(role.id);
      this.selectedRoleFlags.push({
        roleId: role.id,
        isStandAlone: role.roleGroupStandAlone,
      });
    }
    this.roles.setValue(this.selectedRoleIds);
    this.isDropdownValid.roles = this.selectedRoleIds
      ? this.selectedRoleIds.length > 0
      : false;
  }

  initializeCheckbox(roles: IRoles) {
    return this.selectedRoleIds?.some((roleId: number) => roleId === roles.id);
  }

  createUserPayload(): IUserRequest {
    let payload: IUserRequest = {
      id: this.editData ? this.editData.data.id : 0,
      stateId: this.state.value,
      statusTypeId: this.statusTypeId.value,
      statusReason: this.statusReason.value,
      firstName: this.firstName.value,
      middleName: this.middleName.value,
      lastName: this.lastName.value,
      emailName: this.workEmail.value,
      suffix: this.suffix.value,
      agencyId: this.agencyId.value,
      granteeAgencyId: this.granteeAgencyId.value,
      contractorAgencyId: this.contractorAgencyId.value,
      sites: this.sites.value,
      roles: this.roles.value,
      counties: this.counties.value,
      jobTitle: this.jobTitle?.value,
      regions: this.region.value,
    };
    return payload;
  }

  onSubmit() {
    if (
      !this.userForm.value ||
      this.userForm.invalid ||
      (!this.agencyId.value && !this.region.value && !this.counties.value)
    ) {
      this.toast.error($localize `Please enter all required fields.`);
      return;
    }

    const standaloneFlags = new Set(this.selectedRoleFlags.map(flag => flag.isStandAlone));
    if(standaloneFlags.size > 1) {
      this.toast.error('Cannot combine stand alone roles with other roles.');
      return;
    }

    const payload = this.createUserPayload();
    if (this.editData) {
      this.userService
        .updateUser(payload,$localize `Account has been updated.`)
        .pipe(take(1))
        .subscribe((success: boolean) => {
          if (success) {
            this.dialogRef.close({ success: success, payload: payload });
          }
        });
    } else {
      this.userService
        .createUser(
          payload,
          $localize `User account for` + `${payload.firstName} ${payload.lastName}` + $localize `has been successfully created.`
        )
        .pipe(take(1))
        .subscribe((newUserId: number) => {
          if (newUserId > 0) {
            this.dialogRef.close();
          }
        });
    }
  }
}
