import {
  Component,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  combineLatest,
  map,
  mergeMap,
  Observable,
  Subscription,
} from 'rxjs';
import { UserService } from '@core/services/user.service';
import { StateService } from '@core/services/state.service';
import { AgencyService } from '@core/services/agency.service';
import { SiteService } from '@core/services/site.service';
import { RoleService } from '@core/services/role.service';
import { LookupService } from '@core/services/lookup.service';
import { ToastService } from '@core/services/toast.service';
import { IAgency } from '@core/interfaces/iagency';
import { ISites } from '@core/interfaces/isites';
import { IState } from '@core/interfaces/istate';
import { SelectType } from '@core/enums/select';
import { IRoles } from '@core/interfaces/iroles';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IStatusType } from '@core/interfaces/iuser';
import { ICounty } from '@core/interfaces/icounty';
import { RoleGroup } from '@core/enums/roles';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'drdp-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  private subscriptions = new Subscription();
  @ViewChildren('roleCheckbox') roleCheckboxes?: QueryList<MatCheckbox>;
  roleForm: FormGroup | any;
  userId: number = 0;
  userDetails: any;
  userAgencyList: IAgency[] = [];
  userGranteeAgencyList: IAgency[] = [];
  userSiteList: ISites[] = [];
  userCountyList: ICounty[] = [];
  agencyList: IAgency[] = [];
  granteeAgencyList: IAgency[] = [];
  siteList: ISites[] = [];
  stateList: IState[] = [];
  roleList: IRoles[] = [];
  countyList: ICounty[] = [];
  statusTypeList: IStatusType[] = [];
  userStateId: number = 0;
  selectedStateId: number = 0;
  editForm: FormGroup | any;
  accountStatusOptions: any[] = ['Active', 'Inactive'];
  selectedAccountStatus: string = '';
  emitData: any = {};
  originalRoles: number[] = [];

  selectStates = SelectType.States;
  selectAgencies = SelectType.Agencies;
  selectSites = SelectType.Sites;
  selectCounties = SelectType.County;

  getUserDetails$: Observable<any> | undefined;
  updateAgencySiteSelection$: Observable<any> | undefined;

  get emailName() {
    return this.editForm.get('emailName');
  }
  get statusTypeId() {
    return this.editForm.get('statusTypeId');
  }
  get roleIdsToAdd() {
    return this.editForm.get('roleIdsToAdd');
  }
  get roleIdsToDelete() {
    return this.editForm.get('roleIdsToDelete');
  }
  get agencyId() {
    return this.editForm.get('agencyId');
  }
  get granteeAgencyId() {
    return this.editForm.get('granteeAgencyId');
  }
  get contractorAgencyId() {
    return this.editForm.get('contractorAgencyId');
  }
  get siteIdsToAdd() {
    return this.editForm.get('siteIdsToAdd');
  }
  get siteIdsToDelete() {
    return this.editForm.get('siteIdsToDelete');
  }
  get countyIdsToAdd() {
    return this.editForm.get('countyIdsToAdd');
  }
  get countyIdsToDelete() {
    return this.editForm.get('countyIdsToDelete');
  }
  get stateId() {
    return this.editForm.get('stateId');
  }
  get siteIds() {
    return this.editForm.get('siteIds');
  }
  get countyIds() {
    return this.editForm.get('countyIds');
  }
  get roleGroup() {
    return RoleGroup;
  }
  constructor(
    private users: UserService,
    private states: StateService,
    private agencies: AgencyService,
    private toast: ToastService,
    public lookup: LookupService,
    private sites: SiteService,
    private roles: RoleService,
    public dialogRef: MatDialogRef<EditUserComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public user: any
  ) {
    dialogRef.disableClose = true;
    this.roleForm = new FormGroup({});
  }

  ngOnInit(): void {
    this.userId = this.user.id;

    this.getUserDetails$ = this.users.getAdminUserDetails(this.userId).pipe(
      mergeMap((userDetails) =>
        combineLatest({
          agencies: this.agencies.getAgenciesByState([userDetails.stateId]),
          granteeAgencies: this.agencies.getGranteeAgencies(),
          sites: this.sites.getSitesByAgencyId(userDetails.agencyId),
          states: this.states.getAllStates(),
          roles: this.roles.getAllRoles(),
          statusTypes: this.lookup.getAccountStatusTypes(),
          counties: this.lookup.getCountyByStateId(userDetails.stateId),
        }).pipe(map((selectData) => [userDetails, selectData]))
      )
    );

    this.subscriptions.add(
      this.getUserDetails$.subscribe((res) => {
        this.userDetails = res[0];
        this.selectedAccountStatus = this.userDetails.statusTypeName;
        this.userAgencyList = [...res[1].agencies];
        this.userGranteeAgencyList = [...res[1].granteeAgencies];
        this.userSiteList = [...res[1].sites];
        this.userSiteList = [...res[1].sites];
        this.userCountyList = [...res[1].counties];
        this.agencyList = res[1].agencies;
        this.granteeAgencyList = res[1].granteeAgencies;
        this.siteList = res[1].sites;
        this.stateList = res[1].states;
        this.roleList = res[1].roles;
        this.statusTypeList = res[1].statusTypes;
        this.countyList = res[1].counties;
        this.processUserDetail();
        this.initializeForms();
      })
    );
  }

  processUserDetail(): void {
    this.userDetails.roleIds = this.userDetails.roles
      ? this.userDetails.roles.split(',').map(Number)
      : [];
    this.originalRoles = this.userDetails.roles
    ? this.userDetails.roles.split(',').map(Number)
    : [];
    this.userDetails.siteIds = this.userDetails.sites
      ? this.userDetails.sites.split(',').map(Number)
      : [];
    this.userDetails.countyIds = this.userDetails.counties
      ? this.userDetails.counties.split(',').map(Number)
      : [];
    let stateId = this.agencyList.filter(
      (a) => (a.id = this.userDetails.agencyId)
    )[0]?.stateId;
    this.userStateId = stateId ? stateId : this.selectedStateId;
  }

  initializeForms(): void {
    this.editForm = this.fb.group({
      emailName: [
        this.userDetails.emailName,
        [Validators.required, Validators.email, Validators.maxLength(100)],
      ],
      statusTypeId: [this.userDetails.statusTypeId],
      agencyId: [this.userDetails.agencyId, Validators.required],
      granteeAgencyId: [this.userDetails.granteeAgencyId],
      contractorAgencyId: [this.userDetails.contractorAgencyId],
      roleIdsToAdd: [null, Validators.required],
      siteIdsToAdd: [[]],
      countyIdsToAdd: [[]],
      roleIdsToDelete: [[]],
      siteIdsToDelete: [[]],
      countyIdsToDelete: [[]],
      stateId: [this.userStateId, Validators.required],
      siteIds: [this.userDetails.siteIds],
      countyIds: [this.userDetails.countyIds]
    });
    if (this.userDetails.roleIds) {
      this.roleIdsToAdd.setValue(this.userDetails.roleIds);

      // Set up role checkbox form controls
      this.roleList.forEach((role: any) => {
        this.roleForm.addControl(role.id.toString(), new FormControl(false));
        if (this.userDetails.roleIds.includes(role.id)) {
          this.roleForm.get(role.id.toString()).patchValue(true);
        }
      });
    }
  }

  handleChosenAccountStatus(value: any): void {
    if (value.id != this.userDetails.statusTypeId) {
      this.statusTypeId.setValue(value.id);
      this.selectedAccountStatus = value.id;
      this.emitData.statusTypeName = value.name;
    }
  }

  handleChosenState(state: any): void {
    this.stateId.setValue(state?.id)
    this.agencyId.setValue(null);
  }

  handleChosenAgency(agency: any): void {
    this.agencyId.setValue(agency?.id);
    if (this.agencyId.value) this.siteIds.setValidators(Validators.required);
    else this.siteIds.clearValidators();
    this.siteIds.updateValueAndValidity();
  }

  handleChosenSites(sites: any): void {
    const siteIds = sites?.map((s: any) => s.id);
    this.siteIds.setValue(siteIds)
    this.siteIdsToAdd.setValue(
      this.arrayDiff(siteIds, this.userDetails.siteIds)
    );
    this.siteIdsToDelete.setValue(
      this.arrayDiff(this.userDetails.siteIds, siteIds)
    );
  }

  handleChosenCounties(counties: any): void {
    var countyIds = counties.map((c: any) => c.id);
    this.countyIds.setValue(countyIds);
    this.countyIdsToAdd.setValue(
      this.arrayDiff(countyIds, this.userDetails.countyIds)
    );
    this.countyIdsToDelete.setValue(
      this.arrayDiff(this.userDetails.countyIds, countyIds)
    );
  }

  handleChosenGranteeAgency(agency: any): void {
    this.granteeAgencyId.setValue(agency?.id);
  }

  handleChosenContractorAgency(agency: any): void {
    this.contractorAgencyId.setValue(agency?.id);
  }

  handleChosenRoles(role: any): void {
    if (this.roleIdsToAdd.value?.includes(role)) {
      const i = this.roleIdsToAdd.value?.indexOf(role);
      const removeId = this.roleIdsToAdd.value.splice(i, 1);
      this.roleIdsToDelete.value
        ? this.roleIdsToDelete.value.push(removeId)
        : this.roleIdsToDelete.setValue([removeId]);

      if (this.roleIdsToAdd.value.length <= 0) {
        this.roleIdsToAdd.setValue(null);
      }
    } else if (this.roleIdsToDelete.value?.includes(role)) {
      const i = this.roleIdsToDelete.value.indexOf(role);
      const addId = this.roleIdsToDelete.value.splice(i, 1);
      this.roleIdsToAdd.value
        ? this.roleIdsToAdd.value.push(addId)
        : this.roleIdsToAdd.setValue([addId]);
    } else {
      this.roleIdsToAdd.value
        ? this.roleIdsToAdd.value.push(role)
        : this.roleIdsToAdd.setValue([role]);
    }
  }

  resetRoleCheckboxes(): void {
    this.roleCheckboxes?.forEach((roleCheckbox: MatCheckbox) => {
      const roleValue = roleCheckbox.value;
      const isChecked = this.originalRoles.includes(parseInt(roleValue));
      const formControl = this.roleForm.get(roleValue.toString());
  
      if (isChecked) {
        formControl.setValue(true);
      } else {
        formControl.setValue(false);
      }
    });
  }

  undoChanges(): void {
    this.stateId.setValue(this.userStateId);
    this.emailName.setValue(this.userDetails.emailName);
    this.statusTypeId.setValue(this.userDetails.statusTypeId);
    this.agencyId.setValue(this.userDetails.agencyId);
    this.granteeAgencyId.setValue(this.userDetails.granteeAgencyId ?? null);
    this.contractorAgencyId.setValue(this.userDetails.contractorAgencyId ?? null);
    this.roleIdsToAdd.setValue(this.userDetails.roleIds ?? null);
    this.roleIdsToDelete.setValue([]);
    this.siteIds.setValue(this.userDetails.siteIds);
    this.siteIdsToAdd.setValue([]);
    this.siteIdsToDelete.setValue([]);
    this.countyIds.setValue(this.userDetails.countyIds);
    this.agencyList = this.userAgencyList;
    this.siteList = this.userSiteList;
    this.countyList = this.userCountyList;
    this.stateList = [...this.stateList]; // Not too proud of this one but it works...
    this.selectedAccountStatus = this.userDetails.statusTypeName;
    var stateId = this.agencyList.filter(
      (a) => (a.id = this.userDetails.agencyId)
    )[0].stateId;
    this.userStateId = stateId ? stateId : this.selectedStateId;
    this.resetRoleCheckboxes();
  }

  processFormData(): any {
    const payload: any = {};
    const formData = this.editForm.value;

    Object.entries(formData).map(([key, val]) => {
      if (Array.isArray(formData[key]) && formData[key].length > 0) {
        payload[key] = formData[key].join(', ');
      } else if (
        !Array.isArray(formData[key]) &&
        val != this.userDetails[key]
      ) {
        payload[key] = formData[key];
      }
    });
    payload.agencyId = this.agencyId.value;
    payload.granteeAgencyId = this.granteeAgencyId.value;
    payload.contractorAgencyId = this.contractorAgencyId.value;

    return payload;
  }

  processEmitData(): void {
    if (
      this.roleIdsToAdd.value.length > 0 ||
      this.roleIdsToDelete.value.length > 0
    ) {
      var roleIds: any = [];
      roleIds = this.userDetails.roleIds.concat(this.roleIdsToAdd.value);
      roleIds = this.arrayDiff(roleIds, this.roleIdsToDelete.value);

      var roleNames: string = '';
      this.roleList.forEach((r) => {
        if (roleIds.includes(r.id)) roleNames += r.roleName + ', ';
      });
      this.emitData.roleName = roleNames.replace(/,\s*$/, '');
    }

    if (this.editForm.value.emailName != this.userDetails.emailName)
      this.emitData.emailName = this.editForm.value.emailName;
  }

  submit(): void {
    if (this.editForm.invalid) {
      this.toast.warn($localize `Please complete the form before submitting.`);
      return;
    }
    const payload = this.processFormData();
    if (Object.keys(payload).length == 0) {
      this.toast.error($localize `No changes have been made`);
    } else {
      payload.userId = this.userDetails.id;
      this.users.adminUpdateUser(payload).subscribe((success) => {
        if (success) {
          this.toast.success($localize `User updated`);
          this.processEmitData();
          this.dialogRef.close(this.emitData);
        }
      });
    }
  }

  arrayDiff(arr1: any[], arr2: any[]): any[] {
    return arr1.filter((item) => arr2.indexOf(item) == -1);
  }

  arrayIncludes(arr1: any[], arr2: any[]): any[] {
    return arr1.filter((item) => arr2.includes(item));
  }

  initializeCheckbox(roles: IRoles) {
    return this.userDetails.roleIds.some(
      (roleId: number) => roleId === roles.id
    );
  }

  onDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
