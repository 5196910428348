<div class="rounded-lg fixActionRow">
  <div class="flex justify-between border-b">
    <h1 class="text-[25px] font-bold">{{title}}</h1>
    <i mat-button mat-dialog-close class="text-drdpblue-300 cursor-pointer uil uil-times-circle"></i>
  </div>

  <div mat-dialog-content class="relative">
    <form class="grid grid-cols-2 gap-x-4 gap-x-10 py-6" [formGroup]="agencyForm">
      <div class="absolute left-[52%] mt-2">
        <mat-checkbox formControlName="isTestAgency"><span class="font-normal text-base">Test Agency</span></mat-checkbox>
      </div>

      <div class="col-span-2 items-center w-[100%]">
        <label i18n class="font-normal required" for="agencyName">Agency Name</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Agency Name" i18n-placeholder autocomplete="off" id="agencyName" type="text"
            formControlName="agencyName" maxlength="100" required>
        </mat-form-field>
      </div>

      <div>
        <label i18n class="font-normal required" for="cdsCode">Agency Code</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Agency Code" i18n-placeholder autocomplete="off" id="cdsCode" type="text"
            formControlName="cdsCode" maxlength="25" required>
        </mat-form-field>
      </div>

      <div class="select-input flex-col justify-center items-center">
        <drdp-select-site-code [initialCodeId]="codeType.value" (code)="onDropDownSelect($event, selectType.CodeType)"
          [isValid]="!codeType.touched || codeType.valid" [required]="true">
        </drdp-select-site-code>
      </div>

      <div class="select-input flex-col justify-center items-center">
        <drdp-select-state [initialStateId]="stateId.value" (state)="onDropDownSelect($event, selectType.States)"
          [isValid]="!stateId.touched || stateId.valid" [required]="true" [disabled]="!!stateId.value && editData?.data?.id">
        </drdp-select-state>
      </div>

      <div class="select-input flex-col justify-center items-center">
        <drdp-select-type [initialTypeId]="typeId.value" (type)="onDropDownSelect($event, selectType.SiteType)"
          [required]="true" [isValid]="!typeId.touched || typeId.valid">
        </drdp-select-type>
      </div>

      <div class="col-span-2 ">
        <label i18n class="font-normal" for="footer">Footer</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Footer" i18n-placeholder autocomplete="off" id="footer" type="text" formControlName="footer"
            [maxLength]="250">
        </mat-form-field>
      </div>

      <div>
        <label i18n class="font-normal required" for="agencyAdress">Agency Address</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Agency Address" i18n-placeholder autocomplete="off" id="agencyAdress" type="text"
            formControlName="agencyAddress" maxlength="100" required>
        </mat-form-field>
      </div>

      <div>
        <label i18n class="font-normal required" for="agencyCity">Agency City</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Agency City" i18n-placeholder autocomplete="off" id="agencyCity" type="text"
            formControlName="agencyCity" maxlength="25" required>
        </mat-form-field>
      </div>

      <div class="input-container">
        <drdp-select-county [initialCountyIds]="countyId.value" (county)="onDropDownSelect($event, selectType.County)"
          [stateId]="stateId.value" [multiSelect]="false" [multiSelectInitVals]="false"
          [isValid]="!countyId.touched || countyId.valid">
        </drdp-select-county>
      </div>

      <div>
        <label i18n class="font-normal required" for="agencyZipCode">Agency Zip Code</label>
        <mat-form-field class="bg-white" appearance="fill">
          <input matInput placeholder="Agency Zip Code" i18n-placeholder autocomplete="off" id="agencyZipCode" type="text"
            formControlName="agencyZipCode" maxlength="10" required>
        </mat-form-field>
      </div>

      <div>
        <drdp-select-region [stateId]="stateId.value" [initialRegionId]="regionIds.value" [multiSelectInitVals]="true"
          [multiSelect]="true" [isValid]="!regionIds.touched || regionIds.valid"
          (region)="onDropDownSelect($event, selectType.RegionRef)">
        </drdp-select-region>
      </div>

      <div class="select-input flex-col justify-center items-center">
        <drdp-select-vendor [initialVendorIds]="vendorIds.value" [multiSelectInitVals]="true" [multiSelect]="true"
          (vendor)="onDropDownSelect($event, selectType.VendorRef)" [required]="true"
          [isValid]="!vendorIds.touched || vendorIds.valid">
        </drdp-select-vendor>
      </div>

      <div class="select-input flex-col jusityf-center items-center">
        <drdp-select-head-start label="Head Start" i18n-label
          [initialheadStartId]="headStartId.value"
          (headStart)="onHeadStartSelect($event)"
          [stateId]="stateId.value">
        </drdp-select-head-start>
      </div>

      <div class="select-input flex-col justify-center items-center"
        *ngIf="headStartId.value && headStartId.value == HeadStart.HeadStartDelegate">
        <drdp-select-grantee [initialGranteeId]="granteeAgencyId.value"
          [intialAgencyId]="editData.data?.id"
          label="Head Start Grantee Agency" i18n-label
          (agency)="onDropDownSelect($event, selectType.HeadStartGranteeRef)" 
          [required]="true"
          [stateId]="stateId.value">
        </drdp-select-grantee>
      </div>

      <div class="select-input flex-col jusityf-center items-center">
        <drdp-select-state-preschool label="State Preschool" i18n-label
          [initialStatePreschoolId]="statePreschoolId.value"
          (statePreschool)="onStatePreschoolSelect($event)"
          [stateId]="stateId.value">
        </drdp-select-state-preschool>
      </div>

      <div class="select-input flex-col justify-center items-center"
        *ngIf="statePreschoolId.value && statePreschoolId.value == StatePreschool.StatePreschoolSubcontractor">
        <drdp-select-contractor [initialContractorId]="contractorAgencyId.value"
          [intialAgencyId]="editData.data?.id"
          label="State Preschool Contractor Agency"
          i18n-label
          (agency)="onDropDownSelect($event, selectType.StatePreschoolContractorRef)"
          [required]="true"
          [stateId]="stateId.value">
        </drdp-select-contractor>
      </div>

    </form>

  </div>
  <div class="spacer"></div>
  <div mat-dialog-actions class="flex justify-end align-bottom">
    <drdp-btn i18n color="cancel" class="mr-2" mat-button mat-dialog-close (click)="cancel()">Cancel</drdp-btn>
    <drdp-btn i18n mat-button [disabled]="agencyForm.invalid || isProcessing" cdkFocusInitial
      (click)="onSubmit()">Save</drdp-btn>
  </div>
</div>
