<div class="p-5">
    <div class="flex flex-row">
        <div class="basis-1/2">
            <drdp-title title="Portfolio" i18n-title></drdp-title>
        </div>
    </div>

    <drdp-container>
        <drdp-container color="lightBlue">
            <div class="p-3 flex grid md:grid-cols-5 gap-2 flex-row content-between items-end">
                <ng-container class="contents">
                    <div class="col-span-1 z-50">
                        <drdp-select-state #stateSelect [initialStateId]="initStateId" (state)="handleState($event)"
                            [required]="true" [clear]="cleardropDown" [disabled]="!isSuperUser">
                        </drdp-select-state>
                    </div>
                    <div class="col-span-1 z-50">
                        <drdp-select-agency #agencySelect (agency)="handleChosenAgency($event)" [stateId]="stateId.value"
                            [initialAgencyId]="initAgencyId" [required]="true" [clear]="cleardropDown"
                            [disabled]="!isSuperUser">
                        </drdp-select-agency>
                    </div>
                    <div class="col-span-1 z-50">
                        <drdp-select-site #sitesSelect (site)="handleChosenSite($event)" [agencyId]="agencyId.value"
                            [multiSelect]="false" [multiSelectInitVals]="false" [required]="true" [clear]="cleardropDown">
                        </drdp-select-site>
                    </div>
                    <div class="col-span-1 z-50">
                        <drdp-select-rating-period (ratingPeriod)="handleChosenRatingPeriod($event)"
                                   [initialRatingPeriodId]="ratingPeriodId.value"
                                   [agencyId]="agencyId.value"
                                   [required]="true"
                                   [clear]="cleardropDown">
        </drdp-select-rating-period>
                    </div>
                    <div class="col-span-1 z-40">
                        <drdp-filter-select #classroomSelect label="Classroom" i18n-label [options]="classroomList"
                            [selectType]="select.Class" (onSelected)="handleChosenClassroom($event)">
                        </drdp-filter-select>
                    </div>
                    <div class="col-span-1 z-40">
                        <drdp-select-age-grade #ageGroupInstrumentSelect [initialageGradeId]="ageGroupInstrumentId.value" [stateId]="stateId.value" label="Age Group/Instrument" i18n-label
                            [multiSelect]="false" [required]="true" [isPilot]="isPilot" (ageGrade)="handleAgeGroupInstrument($event)" [clear]="cleardropDown">
                        </drdp-select-age-grade>
                    </div>
                    <div class="col-span-1 md:col-start-5 self-end justify-center md:justify-self-end">
                        <drdp-btn i18n type="submit" (click)="submit()" class="mr-2">Search</drdp-btn>
                        <drdp-btn i18n type="button" (click)="clear()" color="outline">Clear</drdp-btn>
                    </div>
                </ng-container>
            </div>
        </drdp-container>
        <div class="p-5 w-full">
            <drdp-add-edit-table viewIcon="uil uil-file-alt text-drdpblue-300" [dataSource]="dataSource" [tableData]="tableData" 
                [columns]="tableColumns" (viewActionItem)="viewStudents($event)" (iconActionItem)="viewClass($event)">
            </drdp-add-edit-table>
        </div>
    </drdp-container>
</div>
<mat-paginator class="pb-6" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" [length]="totalData" [pageIndex]="pageIndex"
    (page)="paginateTable($event)">
</mat-paginator>
