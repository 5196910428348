import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { IAgeGradeInstrument } from '@core/interfaces/iage-grade-instrument';
import { SelectType } from '@core/enums/select';
import { LookupService } from '@core/services/lookup.service';
import { Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'drdp-select-age-grade',
  templateUrl: './select-age-grade.component.html',
  styleUrls: ['./select-age-grade.component.scss'],
})
export class SelectAgeGradeComponent implements OnInit {
  @ViewChild('ageGradeSelect') ageGradeSelect:
    | FilterSelectComponent
    | undefined;
  @Output() ageGrade = new EventEmitter<any>();
  @Input() initialageGradeId?: number | null;
  @Input() stateId?: number | null;
  @Input() clear?: boolean;
  @Input() multiSelect: boolean = true;
  @Input() multiSelectInitVals: boolean = true;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() isValid: boolean = true;
  @Input() label: string = $localize `Age Group/ Instrument`;
  @Input() isPilot: boolean = false;
  @Input() tooltip: string = '';

  private ageGrades$ = new Subject<void>();
  private destroy$ = new Subject<void>();

  ageGradeOptions: IAgeGradeInstrument[] = [];
  public get select() {
    return SelectType;
  }
  constructor(private lookupService: LookupService) {}

  ngOnInit(): void {
    this.getAgeGradeOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.clear) {
      this.ageGradeSelect?.clearSearch(true);
    }

    if (changes['stateId'] || changes['isPilot']) this.getAgeGradeOptions();
  }

  getAgeGradeOptions(): void {
    this.ageGrades$.next();
    this.ageGrades$
      .pipe(
        switchMap(() => {
          if (this.isPilot) return this.lookupService.getPilotAgeGrade();
          else return this.lookupService.getAgeGradeInstruments(this.stateId || 0);
        }),
        takeUntil(this.destroy$)
      ).subscribe((res: IAgeGradeInstrument[]) => {
        this.ageGradeOptions = res;
      });
  }

  clearSelected() {
    if (!this.disabled) this.ageGradeSelect?.clearSelected();
  }

  onAgeGradeSelect(event: number[]): void {
    this.ageGrade.emit(event);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
