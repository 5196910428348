export interface IReportSearch {
  stateId: number | null;
  agencyId: number | null;
  agencyIds: number[] | null;
  ratingPeriodId: number | null;
  siteIds: number[] | null;
  classIds: number[] | null;
  classroomIds: number[] | null;
  studentIds: number[] | null;
  ageGradeInstrumentId: number | null;
  schoolYearId: number | null;
  locale: string;
  globalRatingPeriodIds: number[] | null;
}


export interface IDistrictOsepSearch {
  stateId: number | null;
  agencyId: number | null;
  siteIds: number[] | null;
  schoolYearId: number | null;
  locale: string;
}


export interface IStateOsepSearch {
  stateId: number | null;
  schoolYearId: number | null;
  locale: string;
}
